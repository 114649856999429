import FormInput from "components/common/form/FormInput";
import { Box, BoxProps, CloseButton } from "@chakra-ui/react";
import {
  isOtherName,
  KnownNameFields,
  OtherNameFields,
} from "components/tasks/akas/AkasForm";
import { get, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import FormCheckbox from "components/common/form/FormCheckbox";
import { useEffect } from "react";
import {
  DatePickerInput,
  isStartDateBeforeEndDate,
  validateFormDate,
} from "components/common/form/DatePickerInput";
import { DateTime } from "luxon";

interface NameFormProps extends BoxProps {
  nameId: string;
  defaultValues: KnownNameFields | OtherNameFields;
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export default function AkaForm({
  nameId,
  defaultValues,
  showRemoveButton = false,
  onRemoveClick,
  ...rest
}: NameFormProps): JSX.Element {
  const {
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState,
    formState: { isSubmitting, isValidating },
  } = useFormContext();

  const noMiddleName = !!watch(`${nameId}.noMiddleName`);
  const isOtherNameUsed = isOtherName(defaultValues);

  useEffect(() => {
    if (noMiddleName) {
      setValue(`${nameId}.middleName`, undefined);
      clearErrors(`${nameId}.middleName`);
    }
  }, [noMiddleName]);

  const validateFirstUsedDate = () => {
    const noAdditionalNames = getValues("noAdditionalNames");
    if (noAdditionalNames) {
      return true;
    }

    const firstUsedDatePath = `${nameId}.firstUsedDate`;
    const firstUsedDate = getValues(firstUsedDatePath);
    return validateUsedDate(firstUsedDate);
  };

  const validateLastUsedDate = () => {
    const noAdditionalNames = getValues("noAdditionalNames");
    if (noAdditionalNames) {
      return true;
    }

    const lastUsedDatePath = `${nameId}.lastUsedDate`;
    const lastUsedDate = getValues(lastUsedDatePath);
    return validateUsedDate(lastUsedDate);
  };

  const validateUsedDate = (date: string) => {
    const validOrErrorMessage = validateFormDate(date);
    if (validOrErrorMessage === true) {
      return validateStartAndEndDate();
    }
    return validOrErrorMessage;
  };

  const validateStartAndEndDate = () => {
    const noAdditionalNames = getValues("noAdditionalNames");
    if (noAdditionalNames && noAdditionalNames === true) {
      return true;
    }
    const startDate = getValues(`${nameId}.firstUsedDate`);
    const endDate = getValues(`${nameId}.lastUsedDate`);
    if (startDate && endDate && !isStartDateBeforeEndDate(startDate, endDate)) {
      return "End date must be after Start date";
    }

    return true;
  };

  return (
    <Box className="arrayItemForm" {...rest}>
      {showRemoveButton && (
        <CloseButton
          className="closeButton"
          aria-label={t`Remove name`}
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormInput
        fieldId={`${nameId}.firstName`}
        label={t`First Name`}
        defaultValue={defaultValues.firstName}
        disabled={!isOtherNameUsed}
        maxLength={64}
      />
      <FormCheckbox
        fieldId={`${nameId}.noMiddleName`}
        label={t`No middle name`}
      />
      <FormInput
        required={!noMiddleName}
        fieldId={`${nameId}.middleName`}
        label={t`Middle Name`}
        defaultValue={defaultValues.middleName}
        disabled={noMiddleName}
        maxLength={64}
      />
      <FormInput
        fieldId={`${nameId}.lastName`}
        label={t`Last Name`}
        defaultValue={defaultValues.middleName}
        disabled={!isOtherNameUsed}
        maxLength={64}
      />
      {isOtherNameUsed && (
        <DatePickerInput
          label={t`Date First Used`}
          parentId={nameId}
          fieldIds={{
            year: "firstUsedYear",
            month: "firstUsedMonth",
            day: "firstUsedDay",
            date: "firstUsedDate",
          }}
          validateDate={validateFirstUsedDate}
        />
      )}
      {isOtherNameUsed && (
        <DatePickerInput
          label={t`Date Last Used`}
          parentId={nameId}
          fieldIds={{
            year: "lastUsedYear",
            month: "lastUsedMonth",
            day: "lastUsedDay",
            date: "lastUsedDate",
          }}
          validateDate={validateLastUsedDate}
        />
      )}
    </Box>
  );
}

import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Box, Button, Heading, useToast } from "@chakra-ui/react";
import { Plural, t, Trans } from "@lingui/macro";
import AkaForm from "components/tasks/akas/AkaForm";
import TaskButtons from "components/common/basic/TaskButtons";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import FormCheckbox from "../../common/form/FormCheckbox";
import { DateTime } from "luxon";
import { hasFullDateStringGreaterThanMaxDate } from "components/common/form/DatePickerInput";

export interface NameFields {
  firstName: string;
  noMiddleName: boolean;
  middleName?: string;
  lastName: string;
}

export interface KnownNameFields extends NameFields {
  hiddenPersonAkaId: string;
}

export interface OtherNameFields extends NameFields {
  lastUsedMonth: string;
  lastUsedDay: string;
  lastUsedYear: string;
  lastUsedDate: string;
  firstUsedMonth: string;
  firstUsedDay: string;
  firstUsedYear: string;
  firstUsedDate: string;
}

export function isOtherName(
  fields: KnownNameFields | OtherNameFields,
): fields is KnownNameFields {
  return !(fields as KnownNameFields).hiddenPersonAkaId;
}

const EMPTY_OTHER_NAME = {
  firstName: "",
  noMiddleName: false,
  middleName: "",
  lastName: "",
  lastUsedMonth: "",
  lastUsedDay: "",
  lastUsedYear: "",
  lastUsedDate: "",
  firstUsedMonth: "",
  firstUsedDay: "",
  firstUsedYear: "",
  firstUsedDate: "",
};

export interface AkasFormFields {
  knownNames: KnownNameFields[];
  otherNames: OtherNameFields[];
  noAdditionalNames: boolean;
  doSave?: boolean;
}

export interface AkasFormProps extends TaskComponentProps {
  defaultValues: Omit<AkasFormFields, "doSave">;
  isSavable: boolean;
  onSubmit: SubmitHandler<AkasFormFields>;
}

export const maxDate = DateTime.now().plus({ days: 1 }).startOf("day");

export const maxDateErrorMessage = `Date Last Used must be before ${maxDate?.toLocaleString()}`;

export default function AkasForm({
  defaultValues,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: AkasFormProps): JSX.Element {
  //pre-fill an empty other name if needed
  if (!defaultValues.otherNames.length) {
    defaultValues.otherNames = [EMPTY_OTHER_NAME];
  }

  const toast = useToast();

  const methods = useForm<AkasFormFields>({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "otherNames",
  });

  const showAdditionalNames = !methods.watch("noAdditionalNames");

  const otherNamesForm = "otherNamesForm";

  const validateAkasForm = (data: AkasFormFields) => {
    const errors: string[] = [];

    const { otherNames } = data;

    for (const otherName of otherNames) {
      const { lastUsedDate } = otherName;

      if (hasFullDateStringGreaterThanMaxDate(lastUsedDate, maxDate)) {
        errors.push(maxDateErrorMessage);
      }

      const { firstUsedDate } = otherName;

      if (hasFullDateStringGreaterThanMaxDate(firstUsedDate, maxDate)) {
        errors.push(maxDateErrorMessage);
      }
    }

    return errors;
  };

  const onFormSubmit = (data: AkasFormFields) => {
    const validationErrors = validateAkasForm(data);
    validationErrors.forEach((error) =>
      toast({
        position: "top",
        status: "error",
        variant: "subtle",
        description: error,
        isClosable: true,
        duration: 8000,
      }),
    );

    if (!validationErrors.length) {
      onSubmit(data);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form id={otherNamesForm} onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Heading as="h2" size="md" textAlign="left" w="100%">
            <Plural
              value={defaultValues.knownNames.length}
              one="Name On File"
              other="Names On File"
            />
          </Heading>
          {defaultValues.knownNames.map((knownName, index) => (
            <Box key={`knownNames.${index}`}>
              <AkaForm
                nameId={`knownNames.${index}`}
                defaultValues={knownName}
                bgColor={"gray.100"}
              />
            </Box>
          ))}
          <Heading as="h2" size="md" textAlign="left" w="100%" mb="3px">
            <Trans>Other Names Used</Trans>
          </Heading>
          <FormCheckbox
            fieldId={"noAdditionalNames"}
            label={t`No Other Names`}
          />
          {showAdditionalNames && (
            <>
              {fields.map((otherName, index) => {
                return (
                  <Box key={otherName.id}>
                    <AkaForm
                      nameId={`otherNames.${index}`}
                      defaultValues={otherName}
                      showRemoveButton={fields.length > 1}
                      onRemoveClick={() => {
                        return remove(index);
                      }}
                    />
                  </Box>
                );
              })}
              <Box>
                <Button
                  mt={4}
                  color="gray.600"
                  onClick={() => append(EMPTY_OTHER_NAME)}>
                  + <Trans>Add Another Name</Trans>
                </Button>
              </Box>
            </>
          )}
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={
              (isSavable &&
                (() => onSubmit({ ...methods.getValues(), doSave: true }))) ||
              undefined
            }
            form={otherNamesForm}
          />
        </form>
      </FormProvider>
    </>
  );
}
